import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e4db198"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox flex items-center cursor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event))
    }, null, 512), [
      [_vModelCheckbox, _ctx.data[_ctx.input.id]]
    ]),
    _createElementVNode("span", null, _toDisplayString(_ctx.input.placeholder), 1)
  ]))
}